'use strict'

$(function(){

  $('.responsive-icon').on('click',function(){
    $('.responsive-menu').toggleClass('IsDisplay');
    $('.responsive-icon').toggleClass('IconActive');

  });

});

$(".wpcf7-form-control-wrap label").click(function() {});
